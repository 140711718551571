<template>
<v-app>
<v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title
        class="headline bg-primary w-100"
        primary-title
      >
          {{ title }}
          <v-spacer></v-spacer>
          <div
            class="float-right"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text>
        <div v-html="content"></div>
      </v-card-text>

        <v-card-actions>
          <button
            class="btn btn-link"
            @click="dialog = false"
          >
            Schließen
          </button>
        </v-card-actions>
    </v-card>
</v-dialog>
</v-app>
</template>

<script>
export default {
  name: "Modal",

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    name() {
      return this.$root.name;
    },

    title() {
      return this.$root.title;
    },

    content() {
      return this.$root.content;
    }
  },

  mounted() {
    const openButton = document.querySelector("a[href='#" + this.name + "']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.dialog = true;
      });
    }
  }
}
</script>
